import { createTheme } from "@mui/material/styles"

const typography = {
    fontFamily: `'Inter', sans-serif`
  }
const theme = createTheme({
    palette: {
        red: {
          main: '#A12027'
        }
    },
    typography,
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            '.MuiSelect-select': {
              padding: '8.5px 14px',
            },
            
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              'input': {
                padding: '7.5px 14px'
              }
            },
          },
        },
      },
    }
})

export default theme
