import React from 'react';

import { Typography } from '@mui/material';

const Footer = () => {
    return (
        <div id='footer' className="footer">
            <Typography id='footerText' className="footerText">Didukung oleh:</Typography>
            <img id='kai-footer-logo' src={"./KAIFooterLogo.png"} alt='KAI' height={'17'} width={'42'} />
        </div>
    )
}

export default Footer
