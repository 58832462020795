import React from 'react';
import { Divider, Paper, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TrainIcon from '@mui/icons-material/Train';
import moment from 'moment';
import { getWagonClass } from '../../../common/Utils';

export const StationTicketCard = ({stationTiciketCard}) => ( 
    <Paper id={"selectedStationTicketCard"} className={'paperCards'} style={{padding:"0"}} >
        <div id={"selectedStationTicketCard-date"} style={{padding:"16px 16px 8px 16px", background:"#F2F2F2", borderRadius:"8px 8px 0 0", display:"flex", alignItems:"center"}} >
            <TrainIcon style={{color:"#999", fontSize:"18px", marginRight:"6px"}} />
            <Typography id={"selectedStationTicketCard-date-value"} style={{fontWeight:"500", fontSize:"12px", color:"#555"}} children={moment(stationTiciketCard.departdate,"YYYY-MM-DD").format("ddd, DD MMM YYYY")} />
        </div>
        <div style={{margin:"8px 0", padding:"0 16px"}}>
            <div id={"selectedStationTicketCard-name"} style={{display:"flex", alignItems:"center"}} >
                <Typography id={"selectedStationTicketCard-name-start"} children={`${stationTiciketCard.originStation} (${stationTiciketCard.stasiunOrginCode})`} style={{fontSize:"14px", fontWeight:"bold", color:"#1A1A1A"}} />
                <ArrowForwardIcon fontSize='18' style={{margin:"0 5px"}} className={"iconArrowInSelectInput"} />
                <Typography id={"selectedStationTicketCard-name-end"} children={`${stationTiciketCard.destinationStation} (${stationTiciketCard.stasiunDestCode})`} style={{fontSize:"14px", fontWeight:"bold", color:"#1A1A1A"}} />
            </div>
            <div id={"selectedStationTicketCard-time"} style={{display:"flex", alignItems:"center", marginTop:"4px"}} >
                <Typography id={"selectedStationTicketCard-time-start"} children={moment(stationTiciketCard.departureTime,"HHm").format("HH:mm")} style={{fontSize:"12px", color:"#555"}} />
                <Divider style={{width:"1vw", margin:"0 5px"}} />
                <Typography id={"selectedStationTicketCard-time-end"} children={moment(stationTiciketCard.arrivalTime,"HHm").format("HH:mm")} style={{fontSize:"12px", color:"#555"}} />
            </div>
        </div>
        <div id={"selectedStationTicketCard-class"} style={{display:"flex", alignItems:"center", padding:"0 16px 16px 16px"}} >
            <Typography id={"selectedStationTicketCard-class-start"} children={`${stationTiciketCard.trainName} (${stationTiciketCard.trainNumber})`} style={{fontSize:"12px", color:"#555"}} />
            <Divider style={{width:"1vw", margin:"0 5px"}} />
            <Typography id={"selectedStationTicketCard-class-end"} children={getWagonClass(stationTiciketCard.wagonClass, stationTiciketCard.subClass)} style={{fontSize:"12px", color:"#555"}} />
        </div>
    </Paper>
);