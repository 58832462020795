/* eslint-disable */
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { getWagonClass } from '../../common/Utils';
import { changeSeatAPI } from '../../api/ApiUtils';
import { PopUpModal } from '../CommonComponents/PopUp/PopUpModal';
import { useNavigate } from 'react-router-dom';
import Loader from '../CommonComponents/Loader';

const SubmitSeat = () => {
    const [errorPopUp, showErrorPopUp] = useState({showPopUp: false});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const getNewStamFormSeatIdNew = (list, newSelectedSeat) => {
        for (const item of list) {
            const class_Name = Object.keys(item)[0].split(',')[0];
            const seat_Number = Object.keys(item)[0].split(',')[1];

            const seatClass = getWagonClass(class_Name.split('-')[0], class_Name.split('-')[1]) + ',' + seat_Number; 
            if(seatClass == newSelectedSeat) {
                const selectedSeatDetails = item[`${class_Name},${seat_Number}`]
                return selectedSeatDetails.stamformseatid
            }
        }
        return null;
    };

    const setUpdatedSeat = (data, KAI, oldSeat_stamformseatid, newSeat_stamformseatid) => {
        const {ticketBookingResponse} = KAI;
        
        ticketBookingResponse.data.paxes.map((passenger, i) => {
            if(passenger.stamformseatid == oldSeat_stamformseatid) {
                ticketBookingResponse.data.paxes[i].seat = data[0].seat
                ticketBookingResponse.data.paxes[i].ticketnum = data[0].ticketnum
                ticketBookingResponse.data.paxes[i].stamformdetcode = data[0].stamformdetcode
                ticketBookingResponse.data.paxes[i].stamformseatid = newSeat_stamformseatid
                ticketBookingResponse.data.paxes[i].wagondetrow = data[0].wagondetrow
                ticketBookingResponse.data.paxes[i].wagondetcol = data[0].wagondetcol
            }
        })
        KAI.ticketBookingResponse = ticketBookingResponse;
        localStorage.setItem("KAI",JSON.stringify(KAI));
        navigate("/verefiedinputdata");
    }

    const handleSubmit = async() => {
        const KAI = JSON.parse(localStorage.getItem('KAI'));
        const newSelectedSeat = KAI.seatList.newSelectedSeat;

        const defaultClass = KAI.seatList.currentSeat.stamformdetcode;
        const defaultSelectedSeatClass = getWagonClass(defaultClass.split('-')[0], defaultClass.split('-')[1]);
        const defaultSelectedSeat = KAI.seatList.currentSeat.seat.split(',')[1];

        const defaultSeat = defaultSelectedSeatClass + ',' + defaultSelectedSeat;
        
        // if same default is selected..... ===> defaultSeat !== newSelectedSeat
        if(defaultSeat !== newSelectedSeat && newSelectedSeat && newSelectedSeat !== "" && newSelectedSeat !== defaultSelectedSeatClass) {
            const seatListArray = KAI.seatList.list.data;

            const body = {
                destinationId: KAI.ticketDetails.destinationid,
                originId: KAI.ticketDetails.originid,
                subClass: KAI.ticketBookingResponse.data.subclass,
                tripId: KAI.ticketBookingResponse.data.tripid+"",
                wagonClassId: KAI.ticketBookingResponse.data.wagonclassid,
                bookingCode: KAI.ticketBookingResponse.data.bookingcode,
                noka: KAI.ticketBookingResponse.data.noka,
                stasiunOrginCode: KAI.ticketBookingResponse.data.stasiunorigincode,
                stasiunDestCode: KAI.ticketBookingResponse.data.stasiundestcode,
                wagonClassCode: KAI.ticketBookingResponse.data.wagonclasscode,
                "seats": [
                    {
                        "passengerType": KAI.seatList.currentSeat.passengertype,
                        "stamFormSeatIdOld": KAI.seatList.currentSeat.stamformseatid,
                        "stamFormSeatIdNew": getNewStamFormSeatIdNew(seatListArray, newSelectedSeat),
                        "ticketNum": KAI.seatList.currentSeat.ticketnum
                    }
                ]
            };
            
            setLoading(true);
            
            const response = await changeSeatAPI(body);
            if(response.data.success) { 
                setLoading(false)
                setUpdatedSeat(response.data.data, KAI, body.seats[0].stamFormSeatIdOld, body.seats[0].stamFormSeatIdNew)
            }
            else {
                setLoading(false);
                showErrorPopUp({...errorPopUp, showPopUp: true, message: response.data.message});
            }
        }
        else {
            navigate("/verefiedinputdata");
        }
    };

    const handlePopUpClose = () => {
        showErrorPopUp({...errorPopUp, showPopUp: false});
    };

    return (
        <div style={{padding:"16px 24px", boxShadow:"0px -2px 10px rgba(0, 0, 0, 0.1)"}}>
            <Loader loading={loading} />
            <PopUpModal open={errorPopUp.showPopUp} error={true} message={errorPopUp.message} handlePopUpClose={handlePopUpClose} />
            <Button id="Selsai" children={"Selesai"} className={"submit"} fullWidth onClick={() => handleSubmit()} />
        </div>
    )
}

export default SubmitSeat