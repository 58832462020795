/* eslint-disable */
import axios from "axios";
import { getEnrollmentId } from "../common/Utils";

// getting files from env 
const BaseUrl = process.env.REACT_APP_BASEURL;

const headers = {
  headers: {
    "Content-Type": "application/json",
    "Clientid": "Kai-Enrollment"
  },
};

export const validateEnrollmentId = async (id) => {
  try {
    let requestBody = {
      enrollmentId: id,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/validation";

    const response = await axios.post(url, requestBody, headers);
    return response.data;
  } catch (error) {
    return { success: false };
  }
};

export const getStation = async () => {
  let enrollmentId = null
  try {
    enrollmentId = getEnrollmentId();
    if (!enrollmentId) {
      return { success: false, message: "no enrollment id found in local storage", is_reload: true };
    }
  } catch (error) {
    return { success: false, message: "no enrollment id found in local storage...", is_reload: true };
  };

  try {
    let requestBody = {
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/kai/train/station";
    const response = await axios.post(url, requestBody, headers);
    return response.data;
  } catch (error) {
    return { success: false };
  }
};

export const getSchedule = async (payload) => {
  let enrollmentId = null
  try {
    enrollmentId = getEnrollmentId();
    if (!enrollmentId) {
      return { success: false, message: "no enrollment id found in local storage", is_reload: true };
    }
  } catch (error) {
    return { success: false, message: "no enrollment id found in local storage...", is_reload: true };
  };

  try {
    let requestBody = {
      enrollmentId: enrollmentId,
      ...payload,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/kai/train/schedule";
    const response = await axios.post(url, requestBody, headers);
    if (response.data.success) {
      let KAI = JSON.parse(localStorage.getItem("KAI"));
      KAI.trainScheduleResponse = {
        data: response.data.data || [],
      }
      localStorage.setItem("KAI", JSON.stringify(KAI));
    }
    return response.data;
  } catch (error) {
    return { success: false };
  }
};

export const bookTicketApi = async (payload) => {
  let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

  try {
    let requestBody = {
      enrollmentId: enrollmentId,
      ...payload,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/kai/train/ticket/booking";
    const response = await axios.post(url, requestBody, headers);
    if (response.data.success) {
      let KAI = JSON.parse(localStorage.getItem("KAI"));
      KAI.ticketBookingResponse = {
        data: response.data.data,
      }
      localStorage.setItem("KAI", JSON.stringify(KAI));
    }
    return response.data;
  } catch (error) {
    return { success: false };
  }
};

export const changeSeatAPI = async (payload) => {
  const enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

  try {
    const requestBody = JSON.stringify({
      enrollmentId,
      ...payload
    });
    const URL = BaseUrl + "/kai/train/seat/change";
    const response = await axios.post(URL, requestBody, headers);
    return response;

  } catch (error) {
    // catch the error
  }

}

export const closeEnrollment = async (navigate, step) => {
  try {
    const enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

    const URL = BaseUrl + "/kai/cancel";
    let requestBody = {
      enrollmentId,
      step
    };
    if (step === '4') {
      const KAI = JSON.parse(localStorage.getItem("KAI"));

      requestBody.noka = KAI.noka;
      requestBody.bookingCode = KAI.bookingcode;
    }
    requestBody = JSON.stringify(requestBody);

    console.log(requestBody);
    const response = await axios.post(URL, requestBody, headers);
    if (response.data.success) {
      localStorage.removeItem("KAI");
      navigate("/redirect");
    }
    return response.data;
  }
  catch (error) {
    // catch the error
  }
};
